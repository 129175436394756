<template>
  <v-card>
    <v-card-text v-if="api.isError">
      <AErrorV1 :api="api"></AErrorV1>
    </v-card-text>
    <v-card-text v-if="isSearchable">
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        :label="$t('string.search_by_name')"
        single-line
        hide-details
      ></v-text-field>
    </v-card-text>
    <DataTable
      bread="browse"
      :api="api"
      :items="items"
      :role="role"
      :model="model"
      :search="search"
      :headers="headers"
      :expandable="expandable"
      :parent="parent"
      :modelParent="modelParent"
      :grandParent="grandParent"
      :modelGrandParent="modelGrandParent"
      @refetch="refetchClassroom"
    ></DataTable>
  </v-card>
</template>

<script>
import DataTable from "@/components/Bread/DataTable";
import { mapState } from "vuex";
export default {
  components: {
    DataTable,
  },
  computed: mapState({
    //
  }),
  props: [
    "role",
    "model",
    "url",
    "isTrashed",
    "isSearchable",
    "parent",
    "modelParent",
    "grandParent",
    "modelGrandParent",
  ],
  data: () => ({
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    //BOC:[table]
    items: [],
    //EOC
    //BOC:[search]
    search: "",
    //EOC
    //BOC:[headers]
    headers: [],
    //EOC
    //BOC:[expandable]
    expandable: false,
    //EOC
  }),
  created() {
    //BOC:[headers]
    this.headers = this.$_.cloneDeep(
      this.model[this.role].browse.table.headers
    );
    if (this.model[this.role].read) {
      if (this.isTrashed) {
        this.headers.push({
          text: "",
          value: "actionTrashed",
          sortable: false,
          filterable: false,
        });
      } else {
        this.headers.push({
          text: "",
          value: "actionView",
          sortable: false,
          filterable: false,
        });
      }
    }
    //EOC
    //BOC:[expandable]
    this.expandable =
      this.$_.findIndex(this.model[this.role].browse.table.headers, {
        value: "data-table-expand",
      }) > -1
        ? true
        : false;
    //EOC
    //BOC:[api]
    this.api.method = "get";
    this.api.url = this.url;
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      //BOC:modify count data
      resp.forEach((item) => {
        if (typeof item._count != "undefined") {
          Object.keys(item._count).forEach((key) => {
            item["count" + key] = item._count[key];
          });
        }
      });
      //EOC
      this.items = resp;
    };
    //EOC
  },
  mounted() {
    this.$api.fetch(this.api);
  },
  methods: {
    //
    fetch(){
      this.$api.fetch(this.api);
    },
    refetchClassroom() {
   if (this.model.key == "EventSchoolYear") {
        this.api.url = `${this.$api.servers.event}/api/v1/en/moderator/event/${this.$route.params.id}/schoolYear`;
      } 

      this.$api.fetch(this.api);
    },
  },
};
</script>